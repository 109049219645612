import React, { useRef } from 'react';
import { useClickOutside } from 'hooks';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import { blockTypes } from './blockTypes';

const AddBlockMenu = ({ style = {}, onItemSelect, onClickOutside = () => {} }) => {
  const containerRef = useRef();
  useClickOutside(containerRef, onClickOutside);

  return (
    <div ref={containerRef} className="sc-add-block-menu" style={style}>
      {blockTypes.map(({ slug, label, description, iconName }) => (
        <div key={slug} className="sc-add-block-menu-item" onClick={() => onItemSelect(slug)}>
          <div className="icon-container">
            <Icon name={iconName} />
          </div>
          <div>
            <div style={{ textTransform: 'capitalize' }}><strong>{label || slug.replace(/_/g, ' ')}</strong></div>
            <div className="text-meta">{description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

AddBlockMenu.propTypes = {
  style: PropTypes.object,
  onItemSelect: PropTypes.func,
  onClickOutside: PropTypes.func,
};

export default AddBlockMenu;
