import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import Icon from 'components/common/Icon';
import { DeleteOverlay } from 'components/common/inlines';
import { componentTypes, blockTypesBySlug } from './blockTypes';
import BlockDisplayOptions from './BlockDisplayOptions';


const Block = ({
  type,
  attrs,
  errors = {},
  allowSubItemSource = true,
  allowConditionalDisplay = true,
  releaseItems = [],
  isEditing,
  isExpanded,
  childCount,
  shouldDelete,
  connectDragSource,
  onChange,
  onDisclosureClick,
  onEditClick,
  onDeleteClick,
}) => {
  const Noop = () => null;
  const ChildComponent = componentTypes[type] || Noop;

  const { iconName } = blockTypesBySlug[type] || {};

  const [previewText, setPreviewText] = useState('');
  const label = type.replace(/_/g, ' ');

  if (type === 'group') allowConditionalDisplay = false;

  return (
    <div style={{ position: 'relative' }}>
      <div className={classNames({
        'sc-builder-item': true,
        error: Object.keys(errors).length > 0,
        expanded: isExpanded,
        editing: isEditing,
      })}
      >
        <header>
          {!!onDisclosureClick && <div className="disclosure" onClick={onDisclosureClick} />}
          <div style={{ display: 'flex', alignItems: 'baseline', flex: '1 0 0%', overflow: 'hidden' }}>
            <Icon name={iconName} size={20} className="text-meta mr-1" style={{ position: 'relative', top: 4 }} />
            <h5 className="mb-0" style={{ textTransform: 'capitalize', cursor: 'pointer' }} onClick={onEditClick}>{label}</h5>
            <div className="summary text-meta">{previewText}</div>
          </div>
          <div className="d-flex align-items-center">
            <span
              className="btn-delete tooltip tooltip-left mx-1"
              onClick={onDeleteClick}
              data-tooltip="Remove Block"
            >
              <Icon name="delete" fill />
            </span>

            <span
              className={classNames('btn-edit tooltip tooltip-left mx-1', isEditing && 'active')}
              onClick={onEditClick}
              data-tooltip="Edit Block"
            >
              <Icon name="edit" />
            </span>

            {connectDragSource(
              <span className="btn-move ml-1" title="Drag to sort">
                <Icon name="menu" />
              </span>,
            )}
          </div>
        </header>
        <AnimateHeight duration={500} height={isEditing ? 'auto' : 0}>
          <ChildComponent
            {...attrs}
            errors={errors}
            allowSubItemSource={allowSubItemSource}
            isEditing={isEditing}
            childCount={childCount}
            onPreviewTextUpdate={setPreviewText}
            onChange={onChange}
          />
          {allowConditionalDisplay && (
            <BlockDisplayOptions
              releaseItems={releaseItems}
              conditionalDisplay={attrs.conditionalDisplay}
              conditionalDisplayItems={attrs.conditionalDisplayItems}
              onChange={onChange}
            />
          )}
        </AnimateHeight>
      </div>
      {shouldDelete ? <DeleteOverlay onUndoClick={onDeleteClick} /> : null}
    </div>
  );
};

Block.propTypes = {
  type: PropTypes.string,
  attrs: PropTypes.object,
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  allowConditionalDisplay: PropTypes.bool,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  isEditing: PropTypes.bool,
  isExpanded: PropTypes.bool,
  childCount: PropTypes.number,
  shouldDelete: PropTypes.bool,
  connectDragSource: PropTypes.func,
  onChange: PropTypes.func,
  onDisclosureClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default Block;
