import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { v4 as uuid4 } from 'uuid';
import ResizeDetector from 'react-resize-detector';
import withDragDropContext from 'components/common/withDragDropContext';
import NestedSortableItem from 'components/common/NestedSortableItem';
import Block from './Block';
import AddBlockMenu from './AddBlockMenu';
import blockDefaults from './blockDefaults';


const StructuredContentBuilder = ({
  initialValue = [],
  errors = {},
  allowSubItemSource = true,
  allowConditionalDisplay = true,
  releaseItems = [],
  onChange,
}) => {
  const itemListRef = useRef();
  const itemRefs = useRef({});
  const [blocksById, setBlocksById] = useState({});
  const [blocksOrdered, setBlocksOrdered] = useState([]);

  useEffect(() => {
    if (onChange) {
      const value = blocksOrdered.map((uid, idx) => ({ ...blocksById[uid], index: idx + 1 }));
      onChange(value);
    }
  }, [blocksById, blocksOrdered, onChange]);

  // init data
  useEffect(() => {
    setBlocksById(camelize(initialValue).reduce((result, item) => {
      const { uid, shouldDelete, ...rest } = item;
      result[uid] = { uid, shouldDelete: !!shouldDelete, ...rest };
      return result;
    }, {}));
    setBlocksOrdered(initialValue.map(({ uid }) => uid));
  }, []);

  const updateBlock = (uid, attrs) => setBlocksById(oldState => ({
    ...oldState,
    [uid]: {
      ...oldState[uid],
      ...attrs,
    },
  }));

  const initNewBlock = blockType => {
    const block = {
      blockType,
      uid: uuid4(),
      shouldDelete: false,
      conditionalDisplay: false,
      conditionalDisplayItems: [],
      ...blockDefaults[blockType],
    };

    setBlocksById({ ...blocksById, [block.uid]: block });
    setBlocksOrdered([...blocksOrdered, block.uid]);
    toggleBlockEditingState(block.uid);
  };

  // enable / disable editing state
  const [blocksEditing, setBlocksEditing] = useState({});

  useEffect(() => {
    setBlocksEditing(blocksOrdered.reduce((result, uid) => {
      let editing = uid in blocksEditing ? blocksEditing[uid] : false;
      if (errors[uid]) editing = true;
      result[uid] = editing;
      return result;
    }, {}));
  }, [blocksOrdered]);

  const toggleBlockEditingState = uid => {
    setBlocksEditing({
      ...blocksEditing,
      [uid]: !blocksEditing[uid],
    });
  };

  const toggleAllBlocksEditingState = isEditing => {
    setBlocksEditing(blocksOrdered.reduce((result, uid) => ({ ...result, [uid]: isEditing }), {}));
  };

  const handleEditAllClick = e => {
    e.preventDefault();
    toggleAllBlocksEditingState(true);
  };

  const handleEditNoneClick = e => {
    e.preventDefault();
    toggleAllBlocksEditingState(false);
  };

  // expand / collapse groups
  const [groupsExpanded, setGroupsExpanded] = useState({});

  useEffect(() => {
    setGroupsExpanded(blocksOrdered.reduce((result, uid) => {
      if (blocksById[uid].blockType === 'group') {
        result[uid] = uid in groupsExpanded ? groupsExpanded[uid] : true;
      }
      return result;
    }, {}));
  }, [blocksOrdered]);

  const toggleGroupExpandedState = (uid, state) => setGroupsExpanded(oldState => {
    if (typeof state === 'undefined') state = !oldState[uid];
    return { ...oldState, [uid]: state };
  });

  const toggleAllGroupsExpandedState = isExpanded => {
    setGroupsExpanded(Object.entries(blocksById).reduce((result, [uid, attrs]) => {
      if (attrs.blockType === 'group') {
        result[uid] = isExpanded;
      }
      return result;
    }, {}));
  };

  const handleExpandAllClick = e => {
    e.preventDefault();
    toggleAllGroupsExpandedState(true);
  };

  const handleExpandNoneClick = e => {
    e.preventDefault();
    toggleAllGroupsExpandedState(false);
  };

  // add block menu
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const toggleAddBlockMenu = () => setMenuIsVisible(!menuIsVisible);
  const handleAddBlock = blockType => {
    setMenuIsVisible(false);
    initNewBlock(blockType);
    setTimeout(() => itemListRef.current.scrollTo(0, itemListRef.current.scrollHeight), 100);
  };

  const getItemChildren = uid => blocksOrdered.filter(itemId => blocksById[itemId].group === uid);

  // drag and drop sorting
  const handleItemMove = (sourceId, targetId, insertPos) => {
    const sourceIndex = blocksOrdered.indexOf(sourceId);
    const targetIndex = blocksOrdered.indexOf(targetId);

    const isBefore = insertPos === 'before';
    const isAfter = insertPos === 'after';

    if (isAfter && sourceIndex === targetIndex + 1) return null;
    if (isBefore && sourceIndex === targetIndex - 1) return null;

    const sourceBlock = blocksById[sourceId];
    const targetBlock = blocksById[targetId];
    const targetLevel = targetBlock.group ? 1 : 0;

    const targetHasChildren = getItemChildren(targetId).length > 0;

    // prevent moving a group into another group
    if (sourceBlock.blockType === 'group') {
      if (targetLevel === 1) return null;
      if (targetBlock.blockType === 'group' && isAfter && targetHasChildren) {
        return null;
      }
    }

    let targetGroup = targetBlock.group;
    if (isAfter && targetHasChildren) targetGroup = targetId;

    const sourceChildren = dragSourceChildIds;

    const newValue = blocksOrdered.filter(uid => ![sourceId, ...sourceChildren].includes(uid));
    const movedItems = blocksOrdered.filter(uid => [sourceId, ...sourceChildren].includes(uid));

    const targetNewIndex = newValue.indexOf(targetId);
    const toIndex = isBefore ? targetNewIndex : targetNewIndex + 1;

    newValue.splice(toIndex, 0, ...movedItems);
    setBlocksOrdered(newValue);
    updateBlock(sourceId, { group: targetGroup });
  };

  const [dragSourceChildIds, setDragSourceChildIds] = useState([]);
  const [dragOutlineHeight, setDragOutlineHeight] = useState(null);
  const handleItemDragStart = sourceId => {
    const dragChildren = getItemChildren(sourceId);
    const isCollapsedGroup = groupsExpanded[sourceId] === false;
    // Calculate height of drag preview box encapsulating all child nodes
    if (!isCollapsedGroup && dragChildren.length > 0) {
      const dragSourceEl = findDOMNode(itemRefs.current[sourceId]); // eslint-disable-line
      const lastChildEl = findDOMNode(itemRefs.current[dragChildren.slice(-1)[0]]); // eslint-disable-line
      const height = lastChildEl.getBoundingClientRect().bottom - dragSourceEl.getBoundingClientRect().top;
      setDragOutlineHeight(height);
    }
    setDragSourceChildIds(dragChildren);
  };

  const handleItemDragEnd = sourceId => {
    setDragSourceChildIds([]);
    setDragOutlineHeight(null);
  };

  const blocks = blocksOrdered.map((uid, idx) => {
    let { blockType, shouldDelete, ...attrs } = blocksById[uid];
    const isEditing = blocksEditing[uid];
    const isExpanded = groupsExpanded[uid];
    const isHidden = groupsExpanded[attrs.group] === false;
    const childCount = getItemChildren(uid).length;
    let onDeleteClick = () => updateBlock(uid, { shouldDelete: !shouldDelete });
    if (attrs.group && blocksById[attrs.group].shouldDelete) {
      shouldDelete = true;
      onDeleteClick = undefined;
    }

    const blockProps = {
      type: blockType,
      isEditing,
      isExpanded,
      childCount,
      shouldDelete,
      onDeleteClick,
      errors: errors[uid] || {},
      attrs,
    };
    if (blockType === 'group') {
      blockProps.onDisclosureClick = () => toggleGroupExpandedState(uid);
    }

    const level = attrs.group ? 1 : 0;
    const maxNestingLevel = 1;

    const canSort = !dragSourceChildIds.includes(uid);

    const prevBlock = blocksById[blocksOrdered[idx - 1]];
    const nextBlock = blocksById[blocksOrdered[idx + 1]];
    const canIndent = (
      level === 0 &&
      blockType !== 'group' &&
      prevBlock &&
      (prevBlock.blockType === 'group' || !!prevBlock.group)
    );
    const canOutdent = level === 1 && (!nextBlock || !nextBlock.group);

    const handleLevelChange = (uid, level) => {
      let group = null;
      if (level === 1) {
        group = prevBlock.blockType === 'group' ? prevBlock.uid : prevBlock.group;
        toggleGroupExpandedState(group, true);
      }
      updateBlock(uid, { group });
    };

    return (
      <NestedSortableItem
        key={uid}
        ref={el => itemRefs.current[uid] = el}
        itemId={uid}
        type="sc-builder-items"
        level={level}
        maxLevel={maxNestingLevel}
        // canSort={canSort}
        canIndent={canIndent}
        canOutdent={canOutdent}
        className="mb-4"
        style={{
          opacity: canSort ? 1 : 0,
          display: isHidden ? 'none' : 'block',
          '--drag-outline-height': dragOutlineHeight && `${dragOutlineHeight}px`,
        }}
        onDragStart={handleItemDragStart}
        onDragEnd={handleItemDragEnd}
        onItemMove={handleItemMove}
        onLevelChange={handleLevelChange}
      >
        {(connectDragSource) => (
          <Block
            {...blockProps}
            allowSubItemSource={allowSubItemSource}
            allowConditionalDisplay={allowConditionalDisplay}
            releaseItems={releaseItems}
            connectDragSource={connectDragSource}
            onChange={attrs => updateBlock(uid, attrs)}
            onEditClick={() => toggleBlockEditingState(uid)}
          />
        )}
      </NestedSortableItem>
    );
  });

  const hasError = Object.keys(errors).length > 0;

  return (
    <div className="sc-builder">
      <ResizeDetector handleHeight>
        {({ height }) => (
          <>
            <div className="controls">
              <div className="text-meta d-flex">
                <div className="mr-4">
                  <strong>Expand Groups:</strong>&nbsp;&nbsp;<a href="#expand-all" onClick={handleExpandAllClick}>all</a>&nbsp; | &nbsp;<a href="#expand-none" onClick={handleExpandNoneClick}>none</a>
                </div>
                <div>
                  <strong>Toggle Editing:</strong>&nbsp;&nbsp;<a href="#edit-all" onClick={handleEditAllClick}>all</a>&nbsp; | &nbsp;<a href="#edit-none" onClick={handleEditNoneClick}>none</a>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <button type="button" className="btn btn-primary btn-sm" onClick={toggleAddBlockMenu}>
                  Add Block <i className="icon icon-caret" />
                </button>
                {menuIsVisible && (
                  <AddBlockMenu
                    style={{ maxHeight: height && height - 60 }}
                    onItemSelect={handleAddBlock}
                    onClickOutside={() => setMenuIsVisible(false)}
                  />
                )}
              </div>
            </div>
            {hasError && <div className="error-list">Some of the items below contain errors.</div>}
            <div className="item-list" ref={itemListRef}>
              {blocks.length ? blocks : <div className="text-center my-4">No content yet! Click “Add Block” to get started.</div>}
            </div>
          </>
        )}
      </ResizeDetector>
    </div>
  );
};

StructuredContentBuilder.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  allowConditionalDisplay: PropTypes.bool,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  onChange: PropTypes.func,
};

export default withDragDropContext(StructuredContentBuilder);
