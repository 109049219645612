import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import { pluralize } from 'utils';
import renderBlockErrors from './renderBlockErrors';
import FormGroup from './FormGroup';

const Group = ({ uid, errors, label, childCount, onPreviewTextUpdate, onChange }) => {
  const handleLabelChange = e => onChange({ label: e.target.value });

  useEffect(() => {
    const labelText = [striptags(label || ''), `(${pluralize(childCount, 'item')})`].join(' ');
    onPreviewTextUpdate(labelText);
  }, [label, childCount]);

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Label:">
        <input
          type="text"
          className="form-input"
          onChange={handleLabelChange}
          value={label}
        />
        {renderedErrors.label}
      </FormGroup>
    </div>
  );
};

Group.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  childCount: PropTypes.number,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Group;
