import Button from './Button';
import Credits from './Credits';
import Divider from './Divider';
import DynamicText from './DynamicText';
import Embed from './Embed';
import Group from './Group';
import Heading from './Heading';
import ImageGrid from './ImageGrid';
import Media from './Media';
import PublicationDates from './PublicationDates';
import RelatedLinks from './RelatedLinks';
import Text from './Text';
import Title from './Title';

export {
  Button,
  Credits,
  Divider,
  DynamicText,
  Embed,
  Group,
  Heading,
  ImageGrid,
  Media,
  PublicationDates,
  RelatedLinks,
  Text,
  Title,
};

export { default as FormGroup } from './FormGroup';
export { default as renderBlockErrors } from './renderBlockErrors';

export const blockTypes = [
  { slug: 'group', description: 'Item group (admin interface only)', iconName: 'stacks', component: Group },
  { slug: 'heading', description: 'Heading text', iconName: 'format_h1', component: Heading },
  { slug: 'button', description: 'Button with link', iconName: 'ads_click', component: Button },
  { slug: 'divider', description: 'Horizontal divider / spacer', iconName: 'horizontal_rule', component: Divider },
  { slug: 'text', description: 'Free-form text with basic formatting options', iconName: 'subject', component: Text },
  { slug: 'embed', description: 'Embed code (iframe-based embeds only)', iconName: 'code', component: Embed },
  { slug: 'dynamic_text', description: 'Text derived from a field on an existing object', iconName: 'article_shortcut', component: DynamicText },
  { slug: 'credits', description: 'Credits for the current active item', iconName: 'contacts', component: Credits },
  { slug: 'related_links', description: 'Links related to the current active item', iconName: 'link', component: RelatedLinks },
  { slug: 'publication_dates', description: 'Release published / updated dates', iconName: 'calendar_month', component: PublicationDates },
  { slug: 'media', description: 'Media with optional description and credit', iconName: 'theaters', component: Media },
  { slug: 'title', label: 'Title', description: 'Embedded title object', iconName: 'apps', component: Title },
  { slug: 'image_grid', description: 'Images in a grid layout with optional hyperlinks', iconName: 'grid_on', component: ImageGrid },
];

export const blockTypesBySlug = blockTypes.reduce((result, item) => {
  result[item.slug] = item;
  return result;
}, {});

export const componentTypes = blockTypes.reduce((result, item) => {
  result[item.slug] = item.component;
  return result;
}, {});
